<template>
  <v-avatar :color="color" small :size="size">
    <v-icon color="white" :size="size / 1.75">{{ channel.icon }}</v-icon>
  </v-avatar>
</template>

<script>
export default {
  name: "ChannelAvatar",
  props: {
    channel: {
      type: Object
    },
    size: {
      type: Number,
      required: false,
      default: 32
    }
  },
  computed: {
    color() {
      switch (this.channel.name) {
        case "facebook":
          return "primary";
        case "instagram":
          return "primary";
        default:
          return "primary";
      }
    }
  }
};
</script>
