<template>
  <v-dialog max-width="550" v-model="showDialog">
    <v-card v-if="!loading">
      <v-card-title>
        <div>
          <div class="headline">Copy campaign</div>
          <!--<div class="subtitle-1 grey&#45;&#45;text">-->
          <!--Bekræft ko-->
          <!--</div>-->
        </div>
      </v-card-title>
      <v-card-text v-if="drafts.length < 10">
        Copy <u class="font-italic" v-text="campaign.name"></u> to new draft
      </v-card-text>
      <v-card-text v-else>
        You can only have 10 drafts
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Cancel</v-btn>
        <v-btn
          color="primary"
          :loading="copying"
          :disabled="drafts.length >= 10"
          @click="copy()"
          >Copy</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-if="loading">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    campaign: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: true,
    copying: false,
    drafts: []
  }),
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", ["copyCampaign", "loadCampaigns"]),
    copy() {
      this.copying = true;

      let params = {
        uuid: this.campaign.uuid
      };

      this.copyCampaign(params).then(
        response => {
          this.setSnackSuccess("Succes");
          this.$router.push({
            name: "campaign.create",
            params: { id: response.data.copyCampaign.uuid }
          });
        },
        () => {
          this.copying = false;
          this.setSnackError("Something went wrong");
        }
      );
    }
  },
  created() {
    let params = {
      draft: true,
      page: 1,
      limit: 100
    };

    this.loadCampaigns(params).then(campaigns => {
      this.drafts = campaigns.response;
      this.loading = false;
    });
  }
};
</script>
