<template>
  <div class="pt-2">
    <v-container v-if="!pageInitialized" fluid grid-list-lg class="pt-0">
      <v-layout wrap>
        <v-flex v-for="item in 3" :key="item" md4 sm6 xs12>
          <v-skeleton-loader max-height="300px" type="card"></v-skeleton-loader>
        </v-flex>
      </v-layout>
    </v-container>
    <v-tabs
      v-if="pageInitialized"
      v-model="selectedTab"
      icons-and-text
      :vertical="$vuetify.breakpoint.smAndUp"
      :show-arrows="$vuetify.breakpoint.xsOnly"
      background-color="grey lighten-4"
      @change="tabChange"
    >
      <!-- progressbar -->
      <div class="grey lighten-4 progress-wrapper p-3" v-if="!value.hidden">
        <invited-progress-bar
          :max="value.max_participants"
          :tooltip="'Anbefalet: 30'"
          :value="value.participants"
          labelType="button"
          :height="9"
        />
      </div>

      <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
        {{ tab.label }}
        <v-badge
          :color="tab.name === 'applications' ? 'error' : 'badges'"
          top
          right
          overlap
          offset-x="-5px"
          :value="tab.count > 0"
        >
          <template v-slot:badge>
            {{ tab.count }}
          </template>

          <v-icon color="accent">{{ tab.icon }}</v-icon>
        </v-badge>
      </v-tab>

      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tabs-items v-model="selectedTab" touchless>
        <v-tab-item v-for="tab in tabs" :key="tab.name">
          <v-container
            v-if="tab.items.length > 0"
            fluid
            grid-list-lg
            class="pt-0"
          >
            <v-layout wrap>
              <v-flex
                v-for="campaignInfluencer in tab.items"
                :key="campaignInfluencer.uuid"
                lg4
                sm6
                xs12
              >
                <influencer-card
                  v-if="campaignInfluencer.influencer"
                  :influencer="campaignInfluencer.influencer"
                  :timer="
                    tab.name === 'invitations' || tab.name === 'applications'
                      ? campaignInfluencer
                      : null
                  "
                  flat
                  class="bordered"
                  :ref="campaignInfluencer.influencer.uuid"
                  @delete="openConfirmDeleteDialog($event)"
                >
                  <template v-slot:footer>
                    <v-flex>
                      <v-tooltip top :disabled="$vuetify.breakpoint.xsOnly">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            outlined
                            block
                            color="primary"
                            @click="
                              selectedInfluencer = campaignInfluencer.influencer
                            "
                          >
                            <v-icon>fal fa-chart-line</v-icon>
                          </v-btn>
                        </template>
                        <span>See media data</span>
                      </v-tooltip>
                    </v-flex>

                    <v-flex>
                      <v-tooltip top :disabled="$vuetify.breakpoint.xsOnly">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            outlined
                            block
                            color="primary"
                            @click="
                              selectedCampaignInfluencer = campaignInfluencer
                            "
                          >
                            <v-icon>fal fa-clipboard-list-check</v-icon>
                          </v-btn>
                        </template>
                        <span>See application</span>
                      </v-tooltip>
                    </v-flex>
                  </template>
                </influencer-card>
              </v-flex>
            </v-layout>
            <v-pagination
              v-if="tab.lastPage > 1"
              v-model="page"
              class="my-2"
              :length="tab.lastPage"
              :total-visible="7"
              @input="handlePageChange"
            ></v-pagination>
          </v-container>
          <div v-else class="p-2">
            <v-btn
              :to="{
                path: '/influencers',
                query: {
                  'category_ids[]': value.category.id,
                  'min_followers': value.min_followers,
                  'max_followers': value.max_followers,
                },
              }"
              v-if="value.category"
              color="success"
              >Find influencers 🥳
            </v-btn>
            <img
              v-if="selectedTab === 1"
              class="default-img"
              src="@/assets/mailboxBizz.svg"
              alt="mail box"
            />
            <img
              v-else-if="selectedTab === 0 || selectedTab === 2"
              class="default-img"
              src="@/assets/paperplaneBizz.svg"
              alt="paper plane"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-dialog max-width="400px" v-model="showConfirmDialog">
      <v-card>
        <v-card-title>
          <div class="headline mb-3">Remove influencer</div>
        </v-card-title>
        <v-card-text>
          <div>
            Remove influencer from campaign
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeConfirmDialog()">Annullér</v-btn>
          <v-btn
            color="error"
            :loading="removeInfluencerLoading"
            @click="removeInfluencer()"
          >
            Remove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <campaign-application-dialog
      v-if="selectedCampaignInfluencer"
      v-model="showApplicationDialog"
      :campaign-influencer="selectedCampaignInfluencer"
      :campaign="value"
      :hide-actions="isManaged || isModerator"
      :disable-accept-button="
        !value.registration_open ||
          (value.max_participants &&
            this.tabs[0].count >= value.max_participants)
      "
      :showTimer="
        !selectedCampaignInfluencer.accepted_at &&
        !selectedCampaignInfluencer.declined_at
          ? true
          : false
      "
      @update="loadInfluencers()"
      @deleted="loadInfluencers()"
    ></campaign-application-dialog>
    <influencer-dialog
      v-if="selectedInfluencer"
      v-model="showInfluencerDialog"
      :influencer="selectedInfluencer"
      @change="influencerChange"
    ></influencer-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvitedProgressBar from "@/components/common/progressbars/InvitedProgressBar";
import InfluencerCard from "../../cards/InfluencerCard";
import CampaignApplicationDialog from "../../dialogs/CampaignApplicationDialog";
import InfluencerDialog from "../../dialogs/InfluencerDialog";

export default {
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  components: {
    InvitedProgressBar,
    InfluencerCard,
    CampaignApplicationDialog,
    InfluencerDialog,
  },
  data: () => ({
    pageInitialized: false,
    loading: false,
    selectedTab: 0,
    limit: 12,
    page: 1,
    total: 0,
    tabs: [
      {
        name: "active",
        label: "Active",
        icon: "fal fa-user-check",
        requestType: "active_influencers",
        items: [],
        lastPage: 0,
        count: 0,
      },
      {
        name: "applications",
        label: "Applications",
        icon: "fal fa-user-clock",
        requestType: "pending_applications",
        items: [],
        lastPage: 0,
        count: 0,
      },
      {
        name: "invitations",
        label: "Invitations",
        icon: "fal fa-user-plus",
        requestType: "pending_invitations",
        items: [],
        lastPage: 0,
        count: 0,
      },
      {
        name: "ended",
        label: "Completed",
        icon: "fal fa-user-times",
        requestType: "ended_influencers",
        items: [],
        lastPage: 0,
        count: 0,
      },
      {
        name: "rejections",
        label: "Rejections",
        icon: "fal fa-user-minus",
        requestType: "declined",
        items: [],
        lastPage: 0,
        count: 0,
      },
    ],
    showConfirmDialog: false,
    selectedConnectionId: null,
    selectedCampaignInfluencer: null,
    selectedInfluencer: null,
    removeInfluencerLoading: false,
  }),
  computed: {
    ...mapGetters("core/auth", ["isBusiness", "isModerator"]),
    isManaged() {
      if (this.isBusiness && this.value.agency_id) {
        return true;
      } else {
        return false;
      }
    },
    removeInfluencerEnabled() {
      return this.selectedTab === 0 ||
        this.selectedTab === 2 ||
        this.selectedTab === 3
        ? true
        : false;
    },
    showApplicationDialog: {
      get() {
        return this.selectedCampaignInfluencer !== null;
      },
      set() {
        this.selectedCampaignInfluencer = null;
      },
    },
    showInfluencerDialog: {
      get() {
        return this.selectedInfluencer !== null;
      },
      set() {
        this.selectedInfluencer = null;
      },
    },
  },
  watch: {
    value: {
      handler() {
        this.page = 1;
        this.loadInfluencers();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("core/campaigns", [
      "deleteCampaignInfluencer",
      "getCampaignRequestsForBusiness",
      "getCampaignRequestsForModerator",
    ]),
    loadInfluencers() {
      if (this.loading) {
        return;
      }

      const params = {
        campaign_uuid: this.value.uuid,
        limit: this.limit,
        request_type: this.tabs[this.selectedTab].requestType,
        page: this.page,
      };

      this.loading = true;

      let requestFunction = "getCampaignRequestsForBusiness";
      if (this.isModerator) {
        requestFunction = "getCampaignRequestsForModerator";
      }

      this[requestFunction](params).then(
        (response) => {
          const tabItems = [];

          response.data.forEach((item) => {
            if (item.is_favorite !== undefined && item.influencer) {
              item.influencer.is_favorite = item.is_favorite;
            }
            tabItems.push(item);
          });

          this.tabs[this.selectedTab].items = tabItems;
          this.tabs[this.selectedTab].lastPage = response.lastPage;
          this.tabs[0].count = response.activeInfluencersCount;
          this.tabs[1].count = response.pendingApplicationsCount;
          this.tabs[2].count = response.pendingInvitationsCount;
          this.tabs[3].count = response.endedInfluencersCount;
          this.tabs[4].count = response.declinedCount;
          this.loading = false;
          this.pageInitialized = true;
        },
        () => {
          this.setSnackError("Could not fetch applications");
          this.loading = false;
        }
      );
    },
    removeInfluencer() {
      this.removeInfluencerLoading = true;
      this.deleteCampaignInfluencer({ uuid: this.selectedConnectionId }).then(
        () => {
          this.loadInfluencers();
          this.$forceUpdate();
          this.removeInfluencerLoading = false;
          this.closeConfirmDialog();
          this.setSnackSuccess("Saved");
        },
        () => {
          this.removeInfluencerLoading = false;
          this.setSnackError("Something went wrong.");
        }
      );
    },
    influencerChange() {
      this.loadInfluencers();
    },
    tabChange() {
      this.page = 1;
      this.loadInfluencers();
    },
    handlePageChange() {
      this.loadInfluencers();
      this.scrollToTop();
    },
    closeConfirmDialog() {
      this.showConfirmDialog = false;
      this.selectedConnectionId = null;
    },
    openConfirmDeleteDialog(connection_id) {
      this.showConfirmDialog = true;
      this.selectedConnectionId = connection_id;
    },
  },
  created() {
    this.loadInfluencers();
  },
};
</script>
