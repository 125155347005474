<template>
  <v-tabs
    v-model="selectedTab"
    grow
    icons-and-text
    show-arrows
    background-color="grey lighten-4"
  >
    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.label }}
      <v-icon color="accent">{{ tab.icon }}</v-icon>
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>
    <v-tabs-items v-model="selectedTab" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <information-tab
          v-if="tab.name === 'info'"
          v-model="campaign"
        ></information-tab>
        <influencers-tab
          v-else-if="tab.name === 'influencers'"
          v-model="campaign"
        ></influencers-tab>
        <stats-tab
          v-else-if="tab.name === 'stats'"
          v-model="campaign"
        ></stats-tab>
        <budget-tab
           v-else-if="tab.name === 'budget'"
           v-model="campaign"
        ></budget-tab>
        <sales-tab
          v-else-if="tab.name === 'sales'"
          v-model="campaign"
        ></sales-tab>
        <v-card v-else flat>
          <v-card-text>
            <v-alert :value="true" type="warning">Under construction</v-alert>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import { mapGetters } from "vuex";
import InformationTab from "./InformationTab";
import InfluencersTab from "./InfluencersTab";
import StatsTab from "./StatsTab";
import SalesTab from "./SalesTab";
import BudgetTab from "./BudgetTab";

export default {
  components: { BudgetTab, InformationTab, InfluencersTab, StatsTab, SalesTab },
  props: {
    value: {
      default: null
    }
  },
  data: () => ({
    selectedTab: null
  }),
  computed: {
    ...mapGetters("core/auth", ["isInfluencer"]),
    campaign: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    tabs() {
      let tabs = [];
      tabs.push({
        name: "info",
        label: "Info",
        icon: "fal fa-info"
      });
      if (!this.isInfluencer) {
        tabs.push({
          name: "influencers",
          label: "Influencers",
          icon: "fab fa-instagram"
        });
      }
      tabs.push({
        name: "stats",
        label: "Performance",
        icon: "fal fa-chart-area"
      });
      if (this.value.cost_per_click !== null && ! this.isInfluencer) {
        tabs.push({
          name: "budget",
          label: "Budget",
          icon: "fal fa-abacus"
        });
      }
      tabs.push({
        name: "sales",
        label: "Sales",
        icon: "fal fa-shopping-basket"
      });
      return tabs;
    }
  }
};
</script>
