<template>
  <div>
    <v-card flat>
      <v-card-title>
        <div>
          <div class="headline">Performance</div>
          <a v-if="isClickCampaign" class="subtitle-2" href="https://intercom.help/Make-influence-com/da/articles/6243551-hvordan-udregnes-min-klik-pris" target="_blank">
            Learn how your click price is calculated
          </a>
          <i v-if="! isClickCampaign" class="subtitle-1 light-grey">
            Promo code sales aren't shown on campaigns. <a href="https://www.makeinfluence.com/da/videns-center/rabatkodertilinfluencers" target="_blank">Read more here</a>
          </i>
        </div>
        <v-spacer></v-spacer>

        <v-tooltip :disabled="$vuetify.breakpoint.xsOnly" left>
          <template v-slot:activator="{ on }">
            <v-badge overlap color="badges">
              <template v-if="filtersCount > 0" v-slot:badge>{{
                filtersCount
              }}</template>
              <v-btn
                v-on="on"
                icon
                outlined
                color="primary"
                @click="
                  filtersTmp = cloneDeep(filters);
                  filterDialog = true;
                "
              >
                <v-icon small>fas fa-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-xl class="p-0">
          <v-layout v-if="isClickCampaign" wrap>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-calculator"
                 :title="$options.filters.local_numbers(budgetOverview.remaining_budget_amount)"
                 subtitle="Potential commission"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-money-bill-wave"
                 :title="$options.filters.local_numbers(stats.commission)"
                 subtitle="Commission"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-hand-point-up"
                 :title="$options.filters.local_numbers(stats.clicks)"
                 subtitle="Clicks"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                 icon="fal fa-fingerprint"
                 :title="$options.filters.local_numbers(stats.unique_clicks)"
                 subtitle="Unique clicks"
                 flat
                 bordered
              ></stat-widget>
            </v-flex>
          </v-layout>
          <v-layout v-if="! isClickCampaign" wrap>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-hand-point-up"
                :title="$options.filters.local_numbers(stats.clicks)"
                subtitle="Clicks"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-fingerprint"
                :title="$options.filters.local_numbers(stats.unique_clicks)"
                subtitle="Unique clicks"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-shopping-basket"
                :title="$options.filters.local_numbers(stats.sales)"
                subtitle="Sales"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-percentage"
                :title="$options.filters.local_numbers(stats.conversion) + '%'"
                subtitle="Conversion"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-coins"
                :title="$options.filters.local_numbers(stats.value)"
                subtitle="Revenue"
                flat
                bordered
              ></stat-widget>
            </v-flex>
            <v-flex xs12 sm6 md6 xl4>
              <stat-widget
                icon="fal fa-money-bill-wave"
                :title="$options.filters.local_numbers(stats.commission)"
                subtitle="Commission"
                flat
                bordered
              ></stat-widget>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filter
        </v-card-title>
        <v-card-text>
          <influencer-autocomplete
            v-if="!isInfluencer && filtersTmp.influencer"
            v-model="filtersTmp.influencer.uuid"
            :return-object="false"
          ></influencer-autocomplete>
          <date-picker
            v-model="filtersTmp.start_date"
            v-if="filtersTmp.start_date"
            label="Period start"
            cancel-button
            :reset-button="false"
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            v-if="filtersTmp.end_date"
            label="Period end"
            cancel-button
            :reset-button="false"
          ></date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="setFilter">
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DatePicker from "@/components/common/filters/DatePicker";
import StatWidget from "../../widgets/StatWidget";
import local_numbers from "@/helpers/filters/local_numbers";
import InfluencerAutocomplete from "../../business/autocomplete/InfluencerAutocomplete";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InfluencerAutocomplete,
    StatWidget,
    DatePicker
  },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    stats: {},
    budgetOverview: {},
    filterDialog: false,
    filtersTmp: {},
    filters: {}
  }),
  computed: {
    ...mapGetters("core/auth", ["isInfluencer"]),
    filtersCount() {
      return Object.keys(this.filters).length;
    },
    isClickCampaign() {
      return this.isInfluencer && this.value.cost_per_click !== null;
    }
  },
  methods: {
    ...mapActions("business", ["getBusinessStats"]),
    ...mapActions("core", { loadStats: "getStats", getCampaignBudgetOverview: "getCampaignBudgetOverview" }),

    getStats() {
      let params = this.cloneDeep(this.filters);
      params.campaign_uuid = this.value.uuid;

      const getStatMethod = this.isInfluencer
        ? "loadStats"
        : "getBusinessStats";

      this[getStatMethod](params).then(
        businessStats => {
          this.stats = businessStats[0];
        },
        () => {
          this.setSnackError("Could not fetch data");
        }
      );
    },
    setFilter() {
      this.filters = this.cloneDeep(this.filtersTmp);
      this.filterDialog = false;
      this.getStats();
    },
    getBudgetOverview() {
      if(! this.isClickCampaign) {
        return;
      }

      this.getCampaignBudgetOverview({campaign_id: this.value.uuid}).then(response => {
          this.budgetOverview = response.data.length > 0 ? response.data[0] : {};
        }).catch(err => {
          console.error(err);
          this.setSnackError("Something went wrong.");
        });
    }
  },
  created() {
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment
        .utc(this.value.starting_at, "YYYY-MM-DD HH:mm")
        .format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    }
    this.getStats();

    this.getBudgetOverview();
  }
};
</script>
