<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="50"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.uuid">
            <td v-if="isAgency">
              <sales-status-icon :sale="item"></sales-status-icon>
            </td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
            <td v-if="isAgency">
              <template v-if="item.click">
                {{ timeDifference(item.click.created_at, item.created_at) }}
              </template>
            </td>
            <td class="text-right">{{ item.value | local_numbers }}</td>
            <td class="text-right">{{ item.commission | local_numbers }}</td>
            <td>{{ item.unique_key }}</td>
            <td v-if="isModerator">
              <template v-if="item.click_type">{{ item.click_type }}</template>
              <template v-else>Promo code</template>
            </td>
            <td v-if="isModerator">
              <channel-avatar
                v-if="item.click"
                :channel="item.click.influencer_channel.channel"
                :size="24"
              ></channel-avatar>
            </td>
            <td v-if="! isInfluencer">
              <router-link
                v-if="item.click"
                :to="{
                  name: 'influencer',
                  params: { id: item.click.influencer_channel.influencer.uuid }
                }"
              >
                {{
                  item.click.influencer_channel.influencer.user.first_name +
                    " " +
                    item.click.influencer_channel.influencer.user.last_name
                }}
              </router-link>

              <router-link
                v-else-if="item.promotion_code"
                :to="{
                  name: 'influencer',
                  params: { id: item.promotion_code.influencer.uuid }
                }"
              >
                {{
                  item.promotion_code.influencer.user.first_name +
                    " " +
                    item.promotion_code.influencer.user.last_name
                }}
              </router-link>
            </td>
            <td v-if="! isInfluencer">
              <router-link
                v-if="item.click"
                :title="item.click.campaign.name"
                :to="{
                  name: 'business',
                  params: { id: item.click.campaign.business.uuid }
                }"
              >
                {{ item.click.campaign.business.display_name }}
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'business',
                  params: { id: item.promotion_code.business.uuid }
                }"
              >
                {{ item.promotion_code.business.display_name }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import time_difference from "@/helpers/mixins/time_difference";
import ChannelAvatar from "../avatars/ChannelAvatar";
import { mapGetters } from "vuex";
import SalesStatusIcon from "../icons/SalesStatusIcon";

export default {
  components: { SalesStatusIcon, ChannelAvatar },
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  mixins: [time_difference],
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: Number
    },
    total: {
      type: Number
    }
  },
  data: () => ({
    selectedSale: null,
    currPage: 1
  }),
  computed: {
    ...mapGetters("core/auth", ["isAgency", "isModerator", "isInfluencer"]),
    headers() {
      let headers = [];

      if (this.isAgency) {
        headers.push({ text: "Status", sortable: false });
      }

      headers.push({ text: "Time of sale", value: "created_at" });

      if (this.isAgency) {
        headers.push({ text: "Conversion time", sortable: false });
      }

      headers.push({ text: "Value", value: "value", align: "right" });
      headers.push({ text: "Commission", value: "commission", align: "right" });
      headers.push({ text: "Order number", value: "unique_key" });

      if (this.isModerator) {
        headers.push({ text: "Type", sortable: false });
        headers.push({
          text: "Channel",
          value: "click.influencer_channel.channel.display_name"
        });
      }

      if(! this.isInfluencer) {
        headers.push({ text: "Influencer", sortable: false });
        headers.push({ text: "Company", sortable: false });
      }

      return headers;
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    }
  },
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    }
  }
};
</script>
