<template>
  <campaign-card :campaign="value" mode="edit" flat></campaign-card>
</template>

<script>
import CampaignCard from "../../cards/CampaignCard";

export default {
  components: { CampaignCard },
  props: {
    value: {
      required: true,
      type: Object
    }
  }
};
</script>
