<template>
  <div>
    <template v-if="campaign">
      <div
        :class="{ 'd-flex justify-end': $vuetify.breakpoint.smAndUp }"
        class="campaign-header"
      >
        <expired-timer
          v-if="
            (isInfluencer && hasInvitation) || (isInfluencer && hasApplication)
          "
          template="badge"
          :campaignInfluencer="campaignInfluencer"
        />
        <template v-if="isAgency || (isBusiness && !isManaged)">
          <v-btn
            color="accent"
            class="mb-3 mr-2"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="isManaged"
            @click="showCampaignBulkInvitationDialog = true"
          >
            <v-icon left color="white" small>fal fa-user-plus</v-icon> Invite
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                outlined
                class="mb-3"
                :block="$vuetify.breakpoint.xsOnly"
                v-on="on"
                :disabled="isManaged"
              >
                <v-icon left small>fal fa-cogs</v-icon> Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showCampaignCopyDialog = true">
                <v-list-item-icon>
                  <v-icon>fal fa-copy</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Copy</v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="canStartCampaign"
                @click="openConfirmDialog('start_now')"
              >
                <v-list-item-icon>
                  <v-icon>fal fa-calendar-check</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Start campaign now</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="canCloseCampaign"
                @click="openConfirmDialog('end_now')"
              >
                <v-list-item-icon>
                  <v-icon>fal fa-calendar-minus</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Stop campaign</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-if="isInfluencer">
          <template v-if="!hasCampaign">
            <v-btn
              v-if="campaignInfluencer === null && !campaign.registration_open"
              class="mb-3"
              :block="$vuetify.breakpoint.xsOnly"
              disabled
            >
              Closed for applications
            </v-btn>
            <v-btn
              v-else-if="campaignInfluencer === null && !hasRequirements"
              class="mb-3"
              :block="$vuetify.breakpoint.xsOnly"
              disabled
            >
              Does not meet requirements
            </v-btn>
            <v-btn
              v-else-if="campaignInfluencer === null && hasRequirements"
              color="primary"
              class="mb-3"
              :block="$vuetify.breakpoint.xsOnly"
              @click="showCampaignInvitationDialog = true"
            >
              Apply to campaign
            </v-btn>
            <v-btn
              v-else-if="hasInvitation && !campaignInfluencer.declined_at"
              color="success"
              class="mb-3"
              :block="$vuetify.breakpoint.xsOnly"
              @click="showCampaignApplicationDialog = true"
            >
              See invitation
            </v-btn>
            <v-btn
              v-else-if="hasInvitation && campaignInfluencer.declined_at"
              color="primary"
              outlined
              class="mb-3"
              :block="$vuetify.breakpoint.xsOnly"
              @click="showCampaignApplicationDialog = true"
            >
              Rejected
            </v-btn>
            <v-btn
              v-else-if="hasApplication"
              color="primary"
              class="mb-3"
              outlined
              :block="$vuetify.breakpoint.xsOnly"
              @click="showCampaignApplicationDialog = true"
            >
              See application
            </v-btn>
          </template>
          <!--Has campaign-->
          <template v-else>
            <v-btn
              v-if="campaign.status.name === 'active' && influencerHasCountry"
              color="success"
              class="mb-3"
              :class="{ 'mr-3': $vuetify.breakpoint.smAndUp }"
              :block="$vuetify.breakpoint.xsOnly"
              :to="{
                name: 'tracking_links',
                query: { campaign: campaign.uuid },
              }"
            >
              <v-icon color="white" left>fal fa-rocket</v-icon> Promote campaign
            </v-btn>
            <v-btn
              v-if="campaign.status.name !== 'ended'"
              color="primary"
              class="mb-3"
              outlined
              :block="$vuetify.breakpoint.xsOnly"
              @click="toggleChatDialog(true)"
            >
              Open chat
            </v-btn>

            <v-btn
              v-if="campaign.status.name === 'active'"
              color="success"
              fixed
              bottom
              right
              fab
              :to="{
                name: 'tracking_links',
                query: { campaign: campaign.uuid },
              }"
            >
              <v-icon color="white">fal fa-rocket</v-icon>
            </v-btn>
          </template>
        </template>
      </div>

      <campaign-card
        v-if="isInfluencer && !hasCampaign"
        :campaign="campaign"
      ></campaign-card>
      <campaign-tabs
        v-else
        v-model="campaign"
        class="elevation-2"
      ></campaign-tabs>

      <campaign-copy-dialog
        v-if="!isInfluencer"
        v-model="showCampaignCopyDialog"
        :campaign="campaign"
      ></campaign-copy-dialog>

      <campaign-invitation-dialog
        v-if="isInfluencer && !hasCampaign"
        v-model="showCampaignInvitationDialog"
        :campaign="campaign"
        @success="campaign.active_campaign_request = $event"
      ></campaign-invitation-dialog>

      <campaign-application-dialog
        v-if="isInfluencer && campaignInfluencer"
        v-model="showCampaignApplicationDialog"
        :campaign-influencer="campaignInfluencer"
        :campaign="campaign"
        :disable-accept-button="
          (campaignInfluencer === null || !campaignInfluencer.accepted_at) &&
            !campaign.registration_open
        "
        :showTimer="
          (isInfluencer && hasInvitation) || (isInfluencer && hasApplication)
            ? true
            : false
        "
        @update="getCampaign()"
      ></campaign-application-dialog>
      <bulk-invite-campaign-dialog
        v-model="showCampaignBulkInvitationDialog"
        :selectedCampaign="campaign.uuid"
        @success="campaign.influencers.push($event)"
      >
      </bulk-invite-campaign-dialog>
    </template>
    <template v-else>
      <div class="mb-3 d-flex justify-end">
        <v-skeleton-loader type="button"></v-skeleton-loader>

        <v-skeleton-loader
          v-if="!isInfluencer"
          type="button"
          class="ml-3"
        ></v-skeleton-loader>
      </div>

      <v-skeleton-loader :type="skeletonType"></v-skeleton-loader>
    </template>
    <!-- confirm dialog -->
    <v-dialog v-model="confirmDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline" primary-title>
          <template v-if="confirmAction === 'end_now'">
            Stop campaign
          </template>
          <template v-if="confirmAction === 'start_now'">
            Start campaign
          </template>
        </v-card-title>
        <v-card-text>
          <span v-if="confirmAction === 'end_now'"
            >Your campaign is set to close in 24 hours. Confirm action?</span
          >
          <span v-if="confirmAction === 'start_now'"
            >Campaign will be set to start later today, and hence make promotion
            links available. Confirm action?</span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="mr-2" @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="success"
            :loading="loading"
            @click="campaignDateAction()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <br /><br />
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import CampaignTabs from "../Tabs/CampaignTabs/CampaignTabs";
import CampaignCopyDialog from "../dialogs/CampaignCopyDialog";
import CampaignCard from "../cards/CampaignCard";
import CampaignInvitationDialog from "../dialogs/CampaignInvitationDialog";
import CampaignApplicationDialog from "../dialogs/CampaignApplicationDialog";
import BulkInviteCampaignDialog from "@/components/common/dialogs/BulkInviteCampaignDialog";
import ExpiredTimer from "@/components/common/other/ExpiredTimer.vue";

export default {
  components: {
    CampaignApplicationDialog,
    CampaignInvitationDialog,
    CampaignCard,
    CampaignCopyDialog,
    CampaignTabs,
    BulkInviteCampaignDialog,
    ExpiredTimer,
  },
  data: () => ({
    campaign: null,
    skeletonType:
      "image, article, list-item-three-line, list-item-three-line, article, list-item-three-line, list-item-three-line",
    showCampaignCopyDialog: false,
    showCampaignInvitationDialog: false,
    showCampaignApplicationDialog: false,
    showCampaignBulkInvitationDialog: false,
    day: 1000 * 60 * 60 * 24,
    today: null,
    tomorrow: null,
    start: null,
    end: null,
    confirmDialog: false,
    confirmAction: null,
    loading: false,
  }),
  computed: {
    ...mapGetters("core/auth", ["isAgency", "isBusiness", "isInfluencer"]),
    ...mapState("core/auth", ["user"]),
    influencerHasCountry() {
      return (
        this.isInfluencer &&
        this.user.influencer.country.iso_3166_2 ===
          this.campaign.country.iso_3166_2
      );
    },
    isManaged() {
      if (this.isBusiness && this.campaign.agency_uuid) {
        return true;
      } else {
        return false;
      }
    },
    isManageable() {
      if (
        (this.isAgency &&
          this.campaign.agency &&
          this.campaign.agency.user &&
          this.campaign.agency.user.uuid === this.user.uuid) ||
        (this.isBusiness && !this.campaign.agency)
      ) {
        return true;
      } else {
        return false;
      }
    },
    campaignInfluencer() {
      if (!this.isInfluencer || !this.campaign) {
        return null;
      }

      return this.campaign.active_campaign_request;
    },
    hasCampaign() {
      return (
        this.campaignInfluencer &&
        this.campaignInfluencer.accepted_at &&
        !this.campaignInfluencer.declined_at
      );
    },
    hasRequirements() {
      let totalChannelFollowers = this.user.influencer.channels
        .filter(
          (influencerChannel) =>
            influencerChannel.channel_uuid === this.campaign.channel_uuid
        )
        .reduce((current, nextObject) => {
          return current + nextObject.followers;
        }, 0);
      // Min. followers
      if (
        this.campaign.min_followers &&
        totalChannelFollowers < this.campaign.min_followers
      ) {
        return false;
      }
      return true;
    },
    hasInvitation() {
      return (
        !this.hasCampaign &&
        this.campaignInfluencer &&
        this.campaignInfluencer.invitation
      );
    },
    hasApplication() {
      return (
        !this.hasCampaign &&
        this.campaignInfluencer &&
        !this.campaignInfluencer.invitation
      );
    },
    canCloseCampaign() {
      const endDiff = Math.ceil(
        this.end.diff(this.today.add(24, "hours")) / this.day
      );
      const startDiff = Math.ceil(this.start.diff(this.today) / this.day);
      if (endDiff >= 1 && startDiff <= 0) {
        return true;
      } else {
        return false;
      }
    },
    canStartCampaign() {
      const diff = Math.ceil(this.start.diff(this.today) / this.day);
      if (diff >= 1) {
        return true;
      } else {
        return false;
      }
    },
    canExtendCampaign() {
      const campaignDuration = Math.ceil(this.end.diff(this.start) / this.day);
      const diff = Math.ceil(this.end.diff(this.today) / this.day);
      if (campaignDuration >= 90 || diff > 30) {
        return false;
      } else if (diff < 3) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    // Update campaign(page) if route param changes
    $route() {
      this.getCampaign();
    },
  },
  methods: {
    ...mapActions("core/campaigns", ["updateCampaign", "loadCampaign"]),
    ...mapActions("core/chats", ["toggleChatDialog"]),
    getCampaign() {
      this.campaign = null;
      let params = { uuid: this.$route.params.id };

      this.loadCampaign(params).then((campaign) => {
        this.campaign = campaign;
        this.setCampaignDateInfo();
        // Redir to create page if not published
        if (!this.campaign.published_at) {
          this.$router.push({
            name: "campaign.create",
            params: { id: this.campaign.uuid },
          });
        }
      });
    },
    campaignDateAction() {
      const params = {};
      this.loading = true;
      params[this.confirmAction] = true;
      params.uuid = this.campaign.uuid;

      this.updateCampaign(params).then(
        (updateCampaign) => {
          this.campaign = updateCampaign;
          this.setCampaignDateInfo();
          this.loading = false;
          this.confirmDialog = false;
          this.setSnackSuccess("Saved");
        },
        () => {
          this.loading = false;
          this.setSnackError("Something went wrong.");
        }
      );
    },
    openConfirmDialog(action) {
      this.confirmDialog = true;
      this.confirmAction = action;
    },
    setCampaignDateInfo() {
      this.today = this.$moment();
      this.tomorrow = this.$moment().add(1, "days");
      this.start = this.$moment(this.campaign.starting_at);
      this.end = this.$moment(this.campaign.ending_at);
    },
  },
  created() {
    this.getCampaign();
  },
};
</script>
<style lang="scss" scoped>
.campaign-header {
  position: relative;
  .expired-status-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 100;
    bottom: -58px;
    left: 15px;
    box-shadow: 0 0 10px rgba(1, 49, 56, 0.2) !important;
  }
}
</style>
