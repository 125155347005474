var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":"","items-per-page":50},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[(_vm.isAgency)?_c('td',[_c('sales-status-icon',{attrs:{"sale":item}})],1):_vm._e(),_c('td',{staticClass:"word-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(item.created_at))+" ")]),(_vm.isAgency)?_c('td',[(item.click)?[_vm._v(" "+_vm._s(_vm.timeDifference(item.click.created_at, item.created_at))+" ")]:_vm._e()],2):_vm._e(),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.value)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("local_numbers")(item.commission)))]),_c('td',[_vm._v(_vm._s(item.unique_key))]),(_vm.isModerator)?_c('td',[(item.click_type)?[_vm._v(_vm._s(item.click_type))]:[_vm._v("Promo code")]],2):_vm._e(),(_vm.isModerator)?_c('td',[(item.click)?_c('channel-avatar',{attrs:{"channel":item.click.influencer_channel.channel,"size":24}}):_vm._e()],1):_vm._e(),(! _vm.isInfluencer)?_c('td',[(item.click)?_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.click.influencer_channel.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.click.influencer_channel.influencer.user.first_name + " " + item.click.influencer_channel.influencer.user.last_name)+" ")]):(item.promotion_code)?_c('router-link',{attrs:{"to":{
                name: 'influencer',
                params: { id: item.promotion_code.influencer.uuid }
              }}},[_vm._v(" "+_vm._s(item.promotion_code.influencer.user.first_name + " " + item.promotion_code.influencer.user.last_name)+" ")]):_vm._e()],1):_vm._e(),(! _vm.isInfluencer)?_c('td',[(item.click)?_c('router-link',{attrs:{"title":item.click.campaign.name,"to":{
                name: 'business',
                params: { id: item.click.campaign.business.uuid }
              }}},[_vm._v(" "+_vm._s(item.click.campaign.business.display_name)+" ")]):_c('router-link',{attrs:{"to":{
                name: 'business',
                params: { id: item.promotion_code.business.uuid }
              }}},[_vm._v(" "+_vm._s(item.promotion_code.business.display_name)+" ")])],1):_vm._e()])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }