<template>
  <div>
    <v-card flat>
      <v-card-title>
        <div>
          <div class="headline">Sales</div>
          <i class="subtitle-1 light-grey">
            Sales generated for the campaign. Promo code sales aren't shown on campaigns. <a href="https://www.makeinfluence.com/da/videns-center/rabatkodertilinfluencers" target="_blank">Read more here</a>
          </i>
        </div>
      </v-card-title>
      <v-card-text>
        <sales-data-table
          :items="sales"
          :loading="loading"
          pagination
          :has-less="page > 1"
          :has-more="hasMore"
          :total="total"
          :page="page"
          @changePage="getSalesRequest($event)"
        ></sales-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import SalesDataTable from "@/components/data-tables/SalesDataTable";
import { mapActions } from "vuex";

export default {
  components: { SalesDataTable },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    sales: [],
    loading: false,
    page: 1,
    total: null,
    hasMore: true
  }),
  methods: {
    ...mapActions("core", ["getSales"]),
    getSalesRequest(page = 1) {
      if (this.loading) {
        return null;
      }
      this.page = page;
      this.loading = true;
      this.sales = [];
      let params = {
        start_date: this.$moment
          .utc(this.value.starting_at, "YYYY-MM-DD HH:mm:ss")
          .format("YYYY-MM-DD"),
        end_date: this.$moment().format("YYYY-MM-DD"),
        campaign_uuid: this.value.uuid,
        limit: 100,
        page: page
      };
      this.getSales(params).then(capmaignSales => {
        this.sales = capmaignSales.response;
        // If no more items
        if (this.sales.length !== params.limit) {
          this.hasMore = false;
        }
        this.loading = false;
        this.total = capmaignSales.lastPage;
      });
    }
  },
  created() {
    this.getSalesRequest(1);
  }
};
</script>
