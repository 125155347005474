<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Campaign applications</div>
          <div class="subtitle-1 light-grey">{{ campaign.name }}</div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-list tile dense>
          <!-- expired at -->
          <expired-timer v-if="showTimer" template="list" :campaignInfluencer="campaignInfluencer" />
          <v-list-item v-if="!isBusiness">
            <v-list-item-icon>
              <v-icon>fal fa-building</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                campaign.business.display_name
              }}</v-list-item-title>
              <v-list-item-subtitle>Company</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!isInfluencer">
            <v-list-item-icon>
              <v-icon>fab fa-instagram</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ influencerName }}</v-list-item-title>
              <v-list-item-subtitle>Influencer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!comission.edit"
            @click="changeComissionState(true)"
          >
            <v-list-item-icon>
              <v-icon size="19"
                >fal fa-money-bill-wave</v-icon
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="comissionType === 'fixed'">
                {{ campaignInfluencer.cost_per_action_fixed }}kr. per sale
                <s
                  v-if="
                    campaignInfluencer.cost_per_action_fixed !==
                      campaign.cost_per_action_fixed
                  "
                  class="ml-1"
                >
                  {{
                    campaign.cost_per_action_fixed === null
                      ? 0
                      : campaign.cost_per_action_fixed
                  }}% per sale
                </s>
              </v-list-item-title>
              <v-list-item-title v-if="comissionType === 'percent'">
                {{ campaignInfluencer.cost_per_action_percent }}% per sale
                <s
                  v-if="
                    campaignInfluencer.cost_per_action_percent !==
                      campaign.cost_per_action_percent
                  "
                  class="ml-1"
                >
                  {{
                    campaign.cost_per_action_percent === null
                      ? 0
                      : campaign.cost_per_action_percent
                  }}% per sale
                </s>
              </v-list-item-title>
              <v-list-item-title v-if="comissionType === 'click'">
                {{ campaignInfluencer.cost_per_click }}kr. per click
                <s
                   v-if="
                    campaignInfluencer.cost_per_click !==
                      campaign.cost_per_click
                  "
                   class="ml-1"
                >
                  {{
                    campaign.cost_per_click === null
                       ? 0
                       : campaign.cost_per_click
                  }}kr. per click
                </s>
                <br/>
                {{ campaignInfluencer.max_commission_budget }}kr. max budget
                <s
                   v-if="
                    campaignInfluencer.max_commission_budget !==
                      campaign.max_commission_budget
                  "
                   class="ml-1"
                >
                  {{
                    campaign.max_commission_budget === null
                       ? 0
                       : campaign.max_commission_budget
                  }}kr. max budget
                </s>
              </v-list-item-title>
              <v-list-item-subtitle>
                Commission 
                <span v-if="canChangeCommission"> - click to edit</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- edit form -->
          <v-list-item v-else>
            <v-list-item-icon>
              <v-icon size="19">
                fal fa-money-bill-wave
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div class="d-flex">
                <div v-if="comissionType === 'percent'" class="mr-3 w-100">
                  <v-text-field
                    v-model="comission.value"
                    label="Commission % per sale"
                    :error="comission.error"
                    hint="New value has to be higher than the previous"
                    persistent-hint
                    @input="validateComission"
                  ></v-text-field>
                </div>
                <div v-if="comissionType === 'fixed'" class="mr-3 w-100">
                  <v-text-field
                    v-model="comission.value"
                    label="Commission per sale"
                    :error="comission.error"
                    hint="New value has to be higher than the previous"
                    persistent-hint
                    @input="validateComission"
                  ></v-text-field>
                </div>
                <div v-if="comissionType === 'click'" class="mr-3 w-100">
                  <v-text-field
                     v-model="comission.value"
                     label="Price per click"
                     :error="comission.error"
                     hint="New value has to be higher than the previous (excluding VAT and service fee)"
                     persistent-hint
                     @input="validateComission"
                  ></v-text-field>
                  <v-text-field
                     v-model="comission.maxBudget"
                     label="Max budget"
                     :error="comission.maxError"
                     hint="New value has to be higher than the previous"
                     persistent-hint
                     @input="validateMaxBudget"
                  ></v-text-field>
                </div>
                <div class="d-flex align-center">
                  <div
                    class="mr-3 cursor-pointer"
                    @click.stop="changeComissionState(false)"
                  >
                    <v-icon color="error" size="30">fal fa-times</v-icon>
                  </div>
                  <div class="cursor-pointer" @click.stop="updateComission()">
                    <v-icon color="success" size="30">fal fa-check</v-icon>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!isInfluencer">
            <v-list-item-icon>
              <v-icon v-if="campaignInfluencer.invitation"
                >fal fa-user-plus</v-icon
              >
              <v-icon v-else>fal fa-user-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="campaignInfluencer.invitation"
                >Invited</v-list-item-title
              >
              <v-list-item-title v-else>Applied</v-list-item-title>
              <v-list-item-subtitle>Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="campaignInfluencer.note"
            prepend-icon="fal fa-comment"
            append-icon="fal fa-angle-down"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Click to see message</v-list-item-title>
                <v-list-item-subtitle
                  >Message from {{ noteAuthor }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </template>

            <v-list-item>
              <div class="word-break">
                {{ campaignInfluencer.note }}
              </div>
            </v-list-item>
          </v-list-group>

          <v-list-item v-if="!campaignInfluencer.note">
            <v-list-item-icon>
              <v-icon>fal fa-comment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-italic"
                >No message...</v-list-item-title
              >
              <v-list-item-subtitle>Message</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <template v-if="campaignInfluencer.declined_at">
            <v-list-group
              v-if="campaignInfluencer.declined_reason_text"
              prepend-icon="fal fa-comment-slash"
              append-icon="fal fa-angle-down"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Click to see message</v-list-item-title>
                  <v-list-item-subtitle
                    >Rejection from {{ declineNoteAuthor }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>

              <v-list-item>
                <div class="word-break">
                  {{ campaignInfluencer.declined_reason_text }}
                </div>
              </v-list-item>
            </v-list-group>

            <v-list-item v-if="!campaignInfluencer.declined_reason_text">
              <v-list-item-icon>
                <v-icon>fal fa-comment-slash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-if="campaignInfluencer.declined_reason"
                  class="font-italic"
                >
                  {{ campaignInfluencer.declined_reason | rejection_reasons }}
                </v-list-item-title>
                <v-list-item-title v-else class="font-italic"
                  >No message...</v-list-item-title
                >
                <v-list-item-subtitle>Afvisning</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>fal fa-traffic-light-stop</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="accepted">Accepted</v-list-item-title>
              <v-list-item-title v-else-if="application"
                >Applied - pending
                {{ isBusiness ? "you" : "brand" }}</v-list-item-title
              >
              <v-list-item-title v-else-if="invitation"
                >Invited - pending
                {{ isBusiness ? "influencer" : "you" }}</v-list-item-title
              >
              <v-list-item-title v-else-if="rejected">Rejected</v-list-item-title>
              <v-list-item-subtitle>Status</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-if="showConfirmReject">
          <v-divider class="mt-3 mb-3"></v-divider>

          <div>
            <div class="headline">Rejection reason</div>
            <div class="subtitle-1 light-grey">
              Inform the influencer why they are rejected
            </div>
          </div>

          <v-select
            v-model="selectedRejection"
            label="Rejection reason"
            :items="rejectionReasons"
            item-value="name"
            item-text="label"
            return-object
            class="mt-3"
          ></v-select>

          <v-textarea
            v-if="rejectReasonOther"
            v-model="selectedRejectionNote"
            label="Rejection message"
            hint="Please elaborate why"
            persistent-hint
            color="primary"
            class="mt-2"
            rows="3"
            counter="450"
          ></v-textarea>
        </template>
      </v-card-text>

      <template v-if="! hideActions">
        <v-card-actions v-if="showConfirmReject">
          <v-spacer></v-spacer>

          <v-btn text @click="showConfirmReject = false">
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :loading="actionLoading"
            :disabled="validRejectionReason"
            @click="reject()"
          >
            {{ (invitation || application) ? "Afvis" : "Fjern" }}
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="
            (invitation && isInfluencer) || (application && !isInfluencer)
          "
        >
          <v-spacer></v-spacer>

          <v-btn color="error" outlined @click="showConfirmReject = true">
            Reject
          </v-btn>
          <v-btn color="success" :loading="actionLoading" :disabled="disableAcceptButton" @click="accept()">
            Approve
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="invitation && !isInfluencer">
          <v-spacer></v-spacer>

          <v-btn text @click="showDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            :loading="actionLoading"
            @click="withdraw()"
          >
            Retract invitation
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-else-if="
            (!rejected && !isInfluencer)
          "
        >
          <v-spacer></v-spacer>

          <v-btn color="error" outlined @click="showConfirmReject = true">
            Remove
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import ExpiredTimer from "@/components/common/other/ExpiredTimer";
import rejection_reasons from "@/helpers/filters/rejection_reasons";

export default {
  components: {
    ExpiredTimer
  },
  filters: {
    local_numbers,
    rejection_reasons
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    campaignInfluencer: {
      required: true,
      type: Object,
      default: () => ({})
    },
    campaign: {
      required: true,
      type: Object,
      default: () => ({})
    },
    hideActions: {
      type: Boolean,
      required: false,
      default: false
    },
    disableAcceptButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showTimer: {
      type: Boolean,
      required: false,
      default: null
    }
  },

  data: () => ({
    showConfirmReject: false,
    selectedRejection: null,
    selectedRejectionNote: "",
    actionLoading: false,
    comission: {
      value: 0,
      maxBudget: 0,
      edit: false,
      error: false,
      maxError: false
    }
  }),
  computed: {
    ...mapGetters("core/auth", [
      "isAgency",
      "isBusiness",
      "isInfluencer",
      "isModerator"
    ]),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.confirmAction = false;
        this.note = "";
        this.$emit("input", value);
      }
    },
    influencerName() {
      return (
        this.campaignInfluencer.influencer.user.first_name +
        " " +
        this.campaignInfluencer.influencer.user.last_name
      );
    },
    comissionType() {
      if (this.campaign.cost_per_action_percent) {
        return "percent";
      }
      if (this.campaign.cost_per_action_fixed) {
        return "fixed";
      }
      if (this.campaign.cost_per_click) {
        return "click";
      }
      return false;
    },
    accepted() {
      return (
        this.campaignInfluencer.accepted_at &&
        !this.campaignInfluencer.declined_at &&
        !this.campaignInfluencer.deleted_at
      );
    },
    application() {
      return (
        !this.campaignInfluencer.accepted_at &&
        !this.campaignInfluencer.declined_at &&
        !this.campaignInfluencer.deleted_at &&
        !this.campaignInfluencer.invitation
      );
    },
    invitation() {
      return (
        !this.campaignInfluencer.accepted_at &&
        !this.campaignInfluencer.declined_at &&
        !this.campaignInfluencer.deleted_at &&
        this.campaignInfluencer.invitation
      );
    },
    rejected() {
      return (
        this.campaignInfluencer.declined_at ||
        this.campaignInfluencer.deleted_at
      );
    },
    canChangeCommission() {
      return !this.isInfluencer && !this.rejected;
    },
    validRejectionReason() {
      return (
        !this.selectedRejection ||
        (this.rejectReasonOther && this.selectedRejectionNote.length <= 20)
      );
    },
    rejectionReasons() {
      let reasons = [];

      if (
        !this.isInfluencer
        && !this.rejected
        && !this.invitation
        && !this.application
      ) {
        reasons.push({
          name: "inactivity",
          label: "No longer active",
          text: "No longer active"
        });

        reasons.push({
          name: "misunderstanding_campaign_terms",
          label: "Misunderstood campaign terms",
          text: "Misunderstood campaign terms"
        });

        reasons.push({
          name: "change_of_influencer",
          label: "Change of influencer",
          text: "Change of influencer"
        });

        reasons.push({
          name: "other",
          label: "Other",
          text: "Other"
        });

        return reasons;
      }

      if (this.isInfluencer) {
        reasons.push({
          name: "too_busy",
          label: "Too busy",
          text: "Too busy"
        });

        reasons.push({
          name: "campaign_too_comprehensive",
          label: "Campaign too comprehensive",
          text: "Campaign too comprehensive"
        });

        reasons.push({
          name: "low_commission",
          label: "Commission too low",
          text: "Commission too low"
        });

        reasons.push({
          name: "unfit_for_influencer",
          label: "Campaign doesn't fit profile",
          text: "Campaign doesn't fit profile"
        });
      } else {
        reasons.push({
          name: "not_enough_impressions",
          label: "Not enough impressions",
          text: "Not enough impressions"
        });

        reasons.push({
          name: "unfit_follower_demographic",
          label: "Unfit follower demographic",
          text: "Unfit follower demographic"
        });

        reasons.push({
          name: "unfit_for_brand",
          label: "Unfit for brand",
          text: "Unfit for brand"
        });
      }

      reasons.push({
        name: "other",
        label: "Other",
        text: "Other"
      });

      return reasons;
    },
    rejectReasonOther() {
      return this.selectedRejection && this.selectedRejection.name === "other";
    },
    noteAuthor() {
      if (this.isInfluencer) {
        return this.campaignInfluencer.invitation
          ? this.campaign.business.display_name
          : "You";
      } else if (this.isBusiness) {
        return this.campaignInfluencer.invitation ? "You" : this.influencerName;
      }

      return this.campaignInfluencer.invitation
        ? this.campaign.business.display_name
        : this.influencerName;
    },
    declineNoteAuthor() {
      if (this.isInfluencer) {
        return !this.campaignInfluencer.invitation
          ? this.campaign.business.display_name
          : "You";
      } else if (this.isBusiness) {
        return !this.campaignInfluencer.invitation
          ? "You"
          : this.influencerName;
      }

      return !this.campaignInfluencer.invitation
        ? this.campaign.business.display_name
        : this.influencerName;
    }
  },
  methods: {
    ...mapActions("core/campaigns", [
      "joinCampaignInByInfluencerOnInvite",
      "updateCampaignInfluencer",
      "deleteCampaignInfluencer",
      "retractCampaignRequest"
    ]),
    accept() {
      this.actionLoading = true;

      if (this.isInfluencer) {
        this.joinCampaignInByInfluencerOnInvite({
          uuid: this.campaignInfluencer.uuid,
          accepted: true
        })
          .then(influencer => {
            this.trackVirtualPageView("campaign-invitation-handled");
            this.showDialog = false;
            this.setSnackSuccess("Applied to campaign");
            this.$emit("update", influencer);
          })
          .catch(() => {
            this.setSnackError("Something went wrong");
          });
      } else {
        let params = {
          uuid: this.campaignInfluencer.uuid,
          accepted: true
        };
        this.updateCampaignInfluencer(params)
          .then(response => {
            this.showDialog = false;
            this.setSnackSuccess("Succes");
            this.$emit("update", response);
          })
          .catch(() => {
            this.setSnackError("Something went wrong");
            this.actionLoading = false;
          });
      }
    },
    reject() {
      this.actionLoading = true;
      let reason = {
        type: null,
        text: null
      };
      if (this.rejectReasonOther) {
        reason.text = this.selectedRejectionNote;
      }
      reason.type = this.selectedRejection.name;
      if (this.isInfluencer) {
        this.joinCampaignInByInfluencerOnInvite({
          uuid: this.campaignInfluencer.uuid,
          declined: true,
          declined_reason: reason.type,
          declined_reason_text: reason.text
        })
          .then(influencer => {
            this.trackVirtualPageView("campaign-invitation-handled");
            this.showDialog = false;
            this.setSnackSuccess("Rejected campaign");
            this.$emit("update", influencer);
          })
          .catch(() => {
            this.setSnackError("Something went wrong");
          });
      } else {
        let params = {
          uuid: this.campaignInfluencer.uuid,
          declined_reason: reason.type,
          declined_reason_text: reason.text
        };

        this.retractCampaignRequest(params)
          .then(response => {
            this.showDialog = false;
            this.setSnackSuccess("Succes");
            this.$emit("update", response);
          })
          .catch(() => {
            this.setSnackError("Something went wrong");
            this.actionLoading = false;
          });
      }
    },
    withdraw() {
      this.actionLoading = true;

      this.deleteCampaignInfluencer({ uuid: this.campaignInfluencer.uuid })
        .then(() => {
          this.showDialog = false;
          this.setSnackSuccess("Succes");
          this.$emit("deleted", this.campaignInfluencer);
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
          this.actionLoading = false;
        });
    },
    changeComissionState(value) {
      if (this.canChangeCommission) {
        this.comission.edit = value;
      }
    },
    validateField(currentValue, newValue) {
      const parsedValue = Number(newValue) || !Number.isInteger(Number(newValue));

      if (this.isModerator) {
        return currentValue === parsedValue;
      }

      return parsedValue < currentValue;
    },
    validateComission(value) {
      if (this.comissionType === "percent") {
        this.comission.error = this.validateField(this.campaignInfluencer.cost_per_action_percent, value);
      } else if(this.comissionType === "fixed") {
        this.comission.error = this.validateField(this.campaignInfluencer.cost_per_action_fixed, value);
      } else {
        this.comission.error = this.validateField(this.campaignInfluencer.cost_per_click, value);
      }
    },
    validateMaxBudget(value) {
      this.comission.maxError = this.validateField(this.campaignInfluencer.max_commission_budget, value);
    },
    updateComission() {
      if (this.comission.error || this.comission.maxError) {
        return false;
      } else {
        const params = {
          uuid: this.campaignInfluencer.uuid
        };
        if (this.comissionType === "percent") {
          params.cost_per_action_percent = Number(this.comission.value);
        } else if (this.comissionType === "fixed") {
          params.cost_per_action_fixed = Number(this.comission.value);
        } else {
          params.cost_per_click = parseFloat(this.comission.value);
          params.max_commission_budget = parseFloat(this.comission.maxBudget);
        }
        this.updateCampaignInfluencer(params)
          .then(response => {
            if (this.comissionType === "percent") {
              this.campaignInfluencer.cost_per_action_percent = Number(
                response.cost_per_action_percent
              );
            } else if (this.comissionType === "fixed") {
              this.campaignInfluencer.cost_per_action_fixed = Number(
                 response.cost_per_action_fixed
              );
            } else {
              this.campaignInfluencer.cost_per_click = Number(
                 response.cost_per_click
              );

              this.campaignInfluencer.max_commission_budget = Number(
                 response.max_commission_budget
              );
            }
            this.changeComissionState(false);
          })
          .catch(() => {
            this.setSnackError("Something went wrong");
          });
      }
    }
  },
  created() {
    if (this.comissionType === "percent") {
      this.comission.value = this.campaignInfluencer.cost_per_action_percent;
    } else if (this.comissionType === "fixed") {
      this.comission.value = this.campaignInfluencer.cost_per_action_fixed;
    } else {
      this.comission.value = this.campaignInfluencer.cost_per_click;
      this.comission.maxBudget = this.campaignInfluencer.max_commission_budget;
    }
  }
};
</script>
