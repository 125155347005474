<template>
  <v-card class="card-wrapper" :flat="flat">
    <router-link
      :to="{ name: 'influencer', params: { id: influencer.uuid } }"
      tag="a"
    >
      <div class="p-3 text-center cursor-pointer">
        <v-avatar class="elevation-1 white--text display-1" :size="150">
          <img
            v-show="imageLoaded"
            ref="avatar"
            @load="imageLoaded = true"
            :src="influencer.user.avatar_url"
          />
          <v-skeleton-loader
            v-show="!imageLoaded"
            :loading="true"
            class="rounded"
            height="150px"
            width="150px"
            type="image"
          ></v-skeleton-loader>
          <!-- <template v-else>{{ influencer.user.first_name | first_letter }}</template> -->
        </v-avatar>
      </div>
    </router-link>
    <v-list two-line class="pa-0">
      <!-- expired at -->
      <expired-timer v-if="timer" template="list" :campaignInfluencer="timer" />
      <v-list-item>
        <v-list-item-action>
          <v-icon>fal fa-id-card</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ influencer.user.first_name }} {{ influencer.user.last_name }}
          </v-list-item-title>
          <v-list-item-subtitle
            >{{ influencer.birthday | date_to_age }} year /
            {{
              influencer.gender === "MALE" ? "Male" : "Female"
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-icon>fab fa-instagram</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ followers | local_numbers }}
          </v-list-item-title>
          <v-list-item-subtitle>Instagram followers</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions v-if="$slots.footer">
      <slot name="footer"></slot>
    </v-card-actions>
    <v-flex class="card-action-top-right p-2 cursor-pointer" v-if="isBusiness">
      <favorite-influencer
        :influencer="influencer"
        ref="favoriteComponent"
      ></favorite-influencer>
    </v-flex>
    <v-flex
      class="card-action-top-right pt-2 pr-3 cursor-pointer"
      v-if="isModerator && connection_id"
    >
      <v-icon color="red" medium @click="deleteInfluencer()"
        >fal fa-times</v-icon
      >
    </v-flex>
    <v-flex class="card-badge p-2">
      <v-badge color="badges" v-if="isNew">
        <template v-slot:badge> New </template>
      </v-badge>

      <v-img
        width="30"
        :src="'/img/flags/' + influencer.country.flag"
        class="rounded-sm"
      ></v-img>
    </v-flex>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import date_to_age from "@/helpers/filters/date_to_age";
import first_letter from "@/helpers/filters/first_letter";
import FavoriteInfluencer from "../inputs/FavoriteInfluencer";
import ExpiredTimer from "@/components/common/other/ExpiredTimer";

export default {
  components: {
    FavoriteInfluencer,
    ExpiredTimer,
  },
  props: {
    influencer: {
      required: true,
    },
    connection_id: {
      required: false,
    },
    flat: {
      required: false,
      type: Boolean,
      default: false,
    },
    timer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isNew: null,
      imageLoaded: false,
    };
  },
  filters: { date_to_age, local_numbers, first_letter },
  computed: {
    ...mapGetters("core/auth", ["isBusiness", "isModerator"]),
    followers() {
      return this.influencer.channels.reduce(
        (accumulator, entry) => accumulator + entry.followers,
        0
      );
    },
  },
  methods: {
    updateFavorite(value) {
      this.influencer.is_favorite = value;
      this.$refs.favoriteComponent.updateState(value);
    },
    updateNew() {
      const today = Date.parse(new Date());
      const created_at = Date.parse(this.influencer.created_at);
      this.isNew = today - created_at < 2629746000 ? true : false;
    },
    deleteInfluencer() {
      this.$emit("delete", this.connection_id);
    },
  },
  mounted() {
    this.updateNew();
  },
};
</script>
<style lang="scss" scoped>
.card-wrapper {
  position: relative;
  .card-badge {
    position: absolute;
    left: 7px;
    top: 3px;
  }

  .card-action-top-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
