<template>
  <div>
    <v-card flat>
      <v-card-title>
        <div>
          <div class="headline">Budget overview</div>
          <i class="subtitle-1 light-grey">
            Max budget and the overview is only for click commission. All values excl. VAT.
          </i>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
           :headers="headers"
           :items="items"
           :loading="loading"
           :items-per-page="items.length"
           :hide-default-header="! $vuetify.breakpoint.smAndUp"
           hide-default-footer
        >
          <v-progress-linear
             slot="progress"
             color="primary"
             indeterminate
          ></v-progress-linear>
          <!-- Not influencer -->
          <template v-slot:body="{ items }" v-if="! isInfluencer">
            <tbody>
              <tr v-for="item in items" :key="item.influencer_id">
                <td>{{ item.first_name + ' ' + item.last_name }}</td>
                <td>{{ item.cost_per_click }},-</td>
                <td>{{ item.max_commission_budget_amount }},-</td>
                <td>{{ item.remaining_budget_amount }},-</td>
                <td>{{ item.unique_clicks }}</td>
                <td>{{ item.cost_per_click_realized }},-</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
  },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    loading: false,
    headers: [
      { text: "Influencer", value: "first_name" },
      { text: "CPC", value: "cost_per_click" },
      { text: "Max budget", value: "max_commission_budget_amount" },
      { text: "Remaining budget", value: "remaining_budget_amount" },
      { text: "Unique clicks", value: "unique_clicks" },
      { text: "Realized CPC", value: "cost_per_click_realized" },
    ],
    items: [],
  }),
  computed: {
    ...mapGetters("core/auth", ["isInfluencer"]),
  },
  methods: {
    ...mapActions("core", ["getCampaignBudgetOverview"]),
    refresh() {
      this.loading = true;

      this.getCampaignBudgetOverview({campaign_id: this.value.uuid}).then(response => {
          this.items = response.data;
          this.loading = false;
        }).catch(err => {
          console.error(err);
        this.setSnackError("Something went wrong.");
        });
    }
  },
  created() {
    this.refresh();
  }
};
</script>
