<template>
  <div
    v-if="template === 'badge'"
    class="expired-status-badge py-1 px-3 secondary error--text border-round-xl elevation-2"
  >
    <span class="mr-2 d-flex">
      <v-icon color="error" small>fal fa-clock</v-icon>
    </span>
    <span class="mr-1 d-flex" v-if="expiredTimer !== 'expired'">
      {{textType}} expires in
    </span>
    <span class="mr-1 d-flex" v-else>
      {{textType}} has expired
    </span>
    <span v-if="expiredTimer !== 'expired'" class="d-flex">{{
      expiredTimer
    }}</span>
  </div>
  <v-list-item v-else-if="template === 'list'">
    <v-list-item-action>
      <v-icon color="error">fal fa-clock</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <div class="error--text">
        <span class="mr-1" v-if="expiredTimer !== 'expired'">
          {{textType}} expires in
        </span>
        <span class="mr-1" v-else>
          {{textType}} has expired
        </span>
        <span v-if="expiredTimer !== 'expired'" class="d-flex">{{
          expiredTimer
        }}</span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: "ExpiredTimer",
  props: {
    template: {
      type: String,
      required: false,
      default: "badge"
    },
    campaignInfluencer: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    // data
  }),
  computed: {
    textType() {
      if (this.campaignInfluencer.invitation) {
        return "The invitation";
      } else {
        return "The application";
      }
    },
    expiredTimer() {
      const expired = this.$moment.utc(this.campaignInfluencer.expires_at);
      const now = this.$moment().utc();
      const daysLeft = expired.diff(now, "days");
      const hoursLeft = expired.diff(now, "hours");
      const minsLeft = expired.diff(now, "minutes");
      if (minsLeft < 1) {
        return "expired";
      } else if (hoursLeft < 1) {
        return minsLeft === 1 ? `${minsLeft} minute` : `${minsLeft} minutes`;
      } else if (daysLeft < 1) {
        return hoursLeft === 1 ? `${hoursLeft} hour` : `${hoursLeft} hours`;
      } else {
        return daysLeft === 1 ? `${daysLeft} day` : `${daysLeft} days`;
      }
    }
  }
};
</script>
<style lang="scss">
// styles
</style>
