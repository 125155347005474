<template>
  <v-dialog v-model="showDialog" width="100%" max-width="550px">
    <v-card v-if="!isInfluencer">
      <v-card-title>
        <div>
          <div class="headline">Campaign invitation</div>
          <div class="subtitle-1 light-grey">
            {{ influencer.user.full_name }}
          </div>
        </div>
      </v-card-title>
      <v-card-text class="primary--text">
        <template v-if="!loading">
          <campaign-select
            v-if="filteredCampaigns.length > 0"
            v-model="selectedCampaign"
            :items="filteredCampaigns"
            return-object
          ></campaign-select>
          <div v-else>
            <div class="font-italic mb-2">No campaigns found.</div>
            Either you have no active campaigns, or the influencer is already on it.
          </div>

          <template v-if="selectedCampaign && selectedCampaign.uuid">
            Ideas:
            <ul>
              <li>Why is the influencer suitable for the campaign?</li>
              <li>
                Emphasize relevant points from the campaign (product, brand etc.)
              </li>
            </ul>

            <v-textarea
              v-model="note"
              label="Invitation message"
              counter="450"
              hint="Minimum 50 characters"
              class="mb-3"
              persistent-hint
            ></v-textarea>

            <v-text-field
              v-if="selectedCampaign.cost_per_action_fixed"
              v-model="costPerActionFixed"
              label="Commission amount per lead / sale"
              :hint="
                'Minimum ' + selectedCampaign.cost_per_action_fixed + 'kr.'
              "
              persistent-hint
            ></v-text-field>

            <v-text-field
              v-if="selectedCampaign.cost_per_action_percent"
              v-model="costPerActionPercent"
              label="Commission % per sale"
              :hint="
                'Minimum ' + selectedCampaign.cost_per_action_percent + '%'
              "
              persistent-hint
            ></v-text-field>
          </template>
        </template>
        <v-skeleton-loader v-else type="list-item-avatar"></v-skeleton-loader>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="showDialog = false">
          {{ filteredCampaigns.length > 0 ? "Cancel" : "Done" }}
        </v-btn>
        <v-btn
          v-if="selectedCampaign"
          color="success"
          :disabled="!isInvitationValid"
          :loading="inviteLoading"
          @click="invite()"
        >
          Invite
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else-if="isInfluencer">
      <v-card-title>
        <div>
          <div class="headline">Campaign application</div>
          <div class="subtitle-1 light-grey">{{ campaign.name }}</div>
        </div>
      </v-card-title>
      <v-card-text class="primary--text">
        Ideas:
        <ul>
          <li>What do you like about the campaign / brand?</li>
          <li>Why does it fit you and your followers?</li>
          <li>How would you promote the product?</li>
          <li>Do you have previous experience on a similar campaign?</li>
        </ul>

        <v-textarea
          v-model="note"
          label="Application message"
          :placeholder="'Hi ' + campaign.business.display_name + '...'"
          counter="450"
          hint="Minimum 50 character"
          class="my-2"
          persistent-hint
        ></v-textarea>

        <div>You'll receive a response within 14 days</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="showDialog = false"> Cancel </v-btn>
        <v-btn
          color="success"
          :disabled="!isInvitationValid"
          :loading="inviteLoading"
          @click="join()"
        >
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import local_numbers from "@/helpers/filters/local_numbers";
import CampaignSelect from "../selects/CampaignSelect";

export default {
  components: { CampaignSelect },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    campaign: {
      required: false,
      type: Object,
      default: () => ({})
    },
    influencer: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    campaigns: [],
    selectedCampaign: null,
    note: "",
    costPerActionFixed: null,
    costPerActionPercent: null,
    inviteLoading: false
  }),
  computed: {
    ...mapGetters("core/auth", ["isAgency", "isBusiness", "isInfluencer"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    filteredCampaigns() {
      if (this.isInfluencer) {
        return [];
      }
      return this.campaigns.filter(campaign => {
        return (
          campaign.influencers.filter(
            item => item.influencer.uuid === this.influencer.uuid
          ).length === 0
        );
      });
    },
    noteLength() {
      return this.note.length;
    },
    costPerActionFixedValid() {
      return (
        !this.selectedCampaign ||
        !this.selectedCampaign.cost_per_action_fixed ||
        this.costPerActionFixed >= this.selectedCampaign.cost_per_action_fixed
      );
    },
    costPerActionPercentValid() {
      return (
        !this.selectedCampaign ||
        !this.selectedCampaign.cost_per_action_percent ||
        this.costPerActionPercent >=
          this.selectedCampaign.cost_per_action_percent
      );
    },
    isInvitationValid() {
      if (this.isInfluencer) {
        return this.noteLength >= 50 && this.noteLength < 451;
      }

      return (
        this.selectedCampaign &&
        this.noteLength >= 50 &&
        this.noteLength < 451 &&
        this.costPerActionFixedValid &&
        this.costPerActionPercentValid
      );
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.selectedCampaign = this.cloneDeep(this.campaign);
      }
    },
    selectedCampaign(newValue) {
      this.costPerActionFixed = null;
      this.costPerActionPercent = null;
      this.note = "";

      if (newValue) {
        this.costPerActionFixed = newValue.cost_per_action_fixed;
        this.costPerActionPercent = newValue.cost_per_action_percent;
      }
    }
  },
  methods: {
    ...mapActions("core/campaigns", [
      "loadCampaigns",
      "inviteInfluencerToCampaign",
      "joinCampaignByInfluencer"
    ]),
    invite() {
      this.inviteLoading = true;

      const params = {
        influencer_uuid: this.influencer.uuid,
        campaign_uuid: this.selectedCampaign.uuid,
        note: this.note.replace(/(\r\n|\n|\r)/gm, ""),
        cost_per_action_fixed: Number(this.costPerActionFixed),
        cost_per_action_percent: Number(this.costPerActionPercent)
      };
      if (!this.costPerActionFixed) {
        delete params["cost_per_action_fixed"];
      }
      if (!this.costPerActionPercent) {
        delete params["cost_per_action_percent"];
      }
      this.inviteInfluencerToCampaign(params)
        .then(data => {
          this.setSnackSuccess("Succes");
          // Append invitation so it gets filtered
          this.selectedCampaign.influencers.push(data);
          // Remove the selected value
          this.selectedCampaign = null;
          this.inviteLoading = false;
          this.$emit("success", data);
          if (this.filteredCampaigns.length === 0) {
            this.showDialog = false;
          }
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
          this.inviteLoading = false;
        });
    },

    join() {
      if (!this.isInfluencer) {
        return;
      }

      this.inviteLoading = true;

      this.joinCampaignByInfluencer({
        campaign_uuid: this.campaign.uuid,
        note: this.note
      })
        .then(campaignInfluencer => {
          this.trackVirtualPageView("campaign-invitation-handled");
          this.setSnackSuccess("Succes");
          this.inviteLoading = false;
          this.showDialog = false;
          this.$emit("success", campaignInfluencer);
        })
        .catch(() => {
          this.inviteLoading = false;
          this.setSnackError("Something went wrong");
        });
    },
    loadAllCampaigns() {
      if (!this.isInfluencer || !this.campaign) {
        this.loading = true;

        let params = {
          active_campaigns: true,
          draft: false,
          page: 1,
          limit: 100
        };
        this.loadCampaigns(params).then(campaigns => {
          this.campaigns = campaigns.response;
          this.loading = false;
        });
      }
    }
  },
  created() {
    this.loadAllCampaigns();
  }
};
</script>
